@import 'scss/variables';

.stick {
    position: sticky;
    background-color: var(--secondary-color);
}

.stick.top {
    top: 0;
}

.stick.left {
    left: 0;
    z-index: 9999;
}



.plan-task-table {
    max-height: 300px;

    .record-name {
        min-width: 100px;
    }

    &__wrap {
        margin-top: 20px;
        max-width: 100%;
        overflow: scroll;
        max-height: 500px;
    }

    th,
    td {
        // border-bottom: 1px solid var(--border-color);
        padding: 8px;
        color: var(--active-text-color);
    }

    tr:nth-child(even) {
        background-color: transparent ;
    }

    thead {
        color: var(--text-color);

        tr:last-child {
            border-bottom: 1px solid var(--border-color);

            th:first-child {
                border-left: 1px solid var(--border-color);
            }

        }

        tr:first-child {
            border-right: 1px solid var(--border-color);



            th:nth-child(3) {
                border-left: 1px solid var(--border-color);
            }

            th {
                border-right: 1px solid var(--border-color);
            }
        }
    }

    tbody {
        font-weight: 500;
    }

    &-name {
        width: 170px;
        border-right: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
        &__wrap{
            .role{
                color: var(--green);
            }
            .group{
                color: var(--person-color);
            }
        }
    }

    &-week {
        text-align: center !important;
    }

    &-date {
        top: 30px;
    }

    &-time {
        &__wrap {
            border-right: 1px solid var(--border-color);
            border-bottom: 1px solid var(--border-color);
            width: 180px;
        }

        margin: 0 20px;
        display: flex;
        justify-content: space-between;

        input[type="time"]::-webkit-calendar-picker-indicator {
            filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
        }

        &__item-input {
            background-color: transparent;
            border: none;
            outline: none;
            color: var(--active-text-color);
            text-align: center;
        }
    }

    &__select {
        border: none;
        outline: none;
        width: 100%;
        background-color: transparent;
    }

    &__item {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    &__number {
        border-bottom: 1px solid var(--border-color);
    }

    .number-record {
        background-color: var(--calendar-plan-number);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
        border-radius: 5px;
        text-align: center;
        border: none;
        outline: none;
        font-size: 14px;
        color: var(--active-text-color);
    }
}