.filter-day-night {
    &__item {
        font-weight: 700;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        color: var(--active-text-color);
        font-size: 14px;
        cursor: pointer;
        margin-top: 10px;
        padding-left: 5px;

    }

    &__group {
        margin-top: -20px;
    }

    &__checkbox {
        transform: scale(1.5);
        margin-right: 10px;

        &:checked {
            accent-color: var(--btn-color);
        }
    }
}