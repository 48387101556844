@import 'scss/variables';

.kalendar-plan-dashboard {
    &__feature {
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;
        width: 100%;
        .toggle {
            display: flex;
            align-items: center;

            label {
                margin-right: 20px;
                font-weight: bold;
            }
        }
    }

    &__wrap {

        max-height: 500px;
        overflow-y: scroll;

        .sort-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .status-table {
            tr {
                border: 1px solid var(--border-color);

            }

        }

        .status {
            &__wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                cursor: pointer;
            }

            width: 12px;
            height: 12px;
            background-color: var(--green);
            border-radius: 50%;

            &-table {
                font-size: 12px;

                th {
                    position: sticky;
                    background-color: var(--header-bg);
                }

                td {
                    font-size: 12px;
                }
            }
        }
    }

    &__table {
        background-color: var(--calendar-header-active);
        border-radius: 10px;
        transition: all 0.3s;
        margin-bottom: 20px;

        .sticky {
            position: sticky;
            background-color: var(--calendar-header-active);
            z-index: 2;

            &.th {
                z-index: 2;
            }
        }

        .dashboard-title {
            &:hover {
                .dashboard-title__tooltip {
                    visibility: visible;
                    opacity: 1;
                }

            }

            &__tooltip {
                position: absolute;
                visibility: hidden;
                background-color: var(--hover-selector);
                right: 50%;
                font-size: 10px;
                text-align: left;
                padding: 5px;
                border-radius: 6px;
                z-index: 5;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        td,
        th {
            padding: 10px 20px;
            border: 1px solid var(--border-color);
            border-radius: 10px;
            border-radius: 0;
            transition: all 0.3s;
            text-align: center;
        }

        td {
            font-size: 14px;

            &:nth-child(2) {
                text-align: left;
            }
        }

        th {
            top: -1px;
            z-index: 10;
            min-width: 120px;
            padding: 15px 20px;
        }

        th:nth-child(2) {
            width: 200px;
        }

        .no {
            width: 10px;
            min-width: 10px;
            left: -1px;
        }

        .name {
            left: 55px;
            min-width: 200px;
            &__wrap{
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            &__feature{
                cursor: pointer;
            }

        }


        th.no,
        th.name {
            z-index: 11;
        }

        tr {
            transition: all 0.2s;
        }

        tr:hover {
            background-color: var(--hover-selector);

            td.no,
            td.name {
                background-color: var(--hover-selector);
            }
        }

    }
}

.table-legend {
    display: flex;
    justify-content: center;
    margin: 10px 0;

    .legend-group {
        display: flex;
        margin: 0 10px;
        align-items: center;

        label {
            margin-right: 10px;
        }
    }

    .status {
        width: 12px;
        height: 12px;
        background-color: var(--green);
        border-radius: 50%;
    }
}

.status.wrong {
    background-color: var(--person-color);
}

.status.warning {
    background-color: var(--orange);
}

.status.not-active{
    background-color: #9e9b9b;
}