@import 'scss/variables';

.new-employee__row {
    input {
        border: none;
        outline: none;
        background: transparent;
        text-align: center;
        padding: 5px;
        border-bottom: 1px dashed var(--text-color);
        font-size: 16px;
        font-weight: 500;
        color: var(--active-text-color);
    }
    select{
        padding: 5px;
        border: 1px solid var(--border-color);
        border-radius: 8px;
    }
}

.employee-table__value {
    text-align: center;

}

.lasted-update {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: fit-content;
        margin-left: 10px;
    }
}

input[type=checkbox] {
    margin-right: 5px;
    transform: scale(1.5);
}