.upload-file-form {

    &-group.submit {
        margin-top: 10px;
    }

    &__header {
        display: flex;
        align-items: center;
        position: relative;
        margin-top: -30px;
        margin-left: 10px;
    }
}