@import 'scss/variables';

.task-list-row {
    input {
        border: none;
        outline: none;
        width: 100%;
        background: transparent;
        font-size: 14px;
        color: var(--active-color-text);
    }
 select {
     margin-left: 10px;
     color: var(--active-text-color);
     padding: 10px;
     border-radius: 5px;
     border: 1px solid var(--border-color);
     background-color: var(--calendar-header-item);
     color: var(--active-text-color);
     min-width: 100px;
     text-align: center;
     cursor: pointer;
 }
    .time {
        &__wrap {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        text-align: center;
    }
}