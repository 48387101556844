@import 'scss/variables';

.optimize-process-dashboard {
    &__wrap {
        // position: fixed;
        bottom: 60px;
        right: 40px;
        width: 100%;

    }

    &__item {
        color: var(--active-text-color);

        &-title {
            font-weight: 700;
        }

        &-progress {
            display: flex;
            align-items: center;
            margin: 10px 0;

            .percent {
                margin-left: 10px;
            }
        }

        &-status {
            width: 90%;
            display: flex;
            justify-content: center;
            align-items: center;

            .loading {
                font-weight: bold;
                display: inline-block;
                font-family: monospace;
                font-size: 20px;
                clip-path: inset(0 3ch 0 0);
                animation: l 2s steps(4) infinite;
            }

            @keyframes l {
                to {
                    clip-path: inset(0 -1ch 0 0)
                }
            }
        }
    }


    &__content {
        background-color: var(--card-bg);
        min-width: 300px;
        min-height: 200px;
        border-radius: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 30px;
        transition: all 0.3s ease-in-out;
        max-height: 300px;
        overflow: scroll;
    }

}