@import 'scss/variables';

.calendar-survey-sub-question {
    &__content {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: var(--modal-background);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    &__wrap {
        width: 60%;
        min-height: 70%;
        max-height: 70%;
        padding: 20px;
        background-color: var(--body-bg);
        overflow: scroll;

        .sub-question__item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 0;
            border-bottom: 1px solid;
        }

        .sub-question__feature {
            svg {
                margin: 0 5px;
            }
        }
    }

    &__close {
        position: absolute;
        right: 20px;

        &:hover {
            color: var(--text-color);
        }
    }

    &__childs {
        margin-top: 20px;
    }


}