@import 'scss/variables';

.sort-group {
    margin: 10px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    &.increase {
        .sort-icon:nth-child(1) {
            color: var(--green);
        }

        .sort-icon:nth-child(2) {
            color: var(--text-active-color)
        }
    }

    &.decrease {
        .sort-icon:nth-child(1) {
            color: var(--text-active-color)
        }

        .sort-icon:nth-child(2) {
            color: var(--person-color);
        }

    }
}