@import "scss/variables";

.calendar-plan-employee {
    padding: 30px;
    .loading-icon{
        margin-left: 10px;
    }
    &.question {
        width: 95%;
    }

    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        h3 {
            color: var(--active-text-color);
        }
    }

    &__wrap {
        width: 100%;
        position: relative;
        overflow: scroll;
        max-height: 700px;

        .download-table-xls-button {
            margin-bottom: 20px;
        }
    }

    &__feature {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;

       

        label,
        button {
            margin: 0 5px;
            padding: 10px;
            border-radius: 5px !important;
        }

        label {
            font-size: 13px;
            font-weight: 700 !important;
        }
    }

    &__pagination{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__table {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;
        color: var(--active-text-color);
        background-color: var(--calendar-header-active);
        border-radius: 10px;

        .employee-table {
            &__select {
                width: 50px;
            }

            &__value {
                min-width: 85px;
                text-align: center;
            }

            &__name {
                min-width: 150px;
            }

            &__no {
                max-width: 10px;
            }

            &__date {
                min-width: 150px;
            }

            &__feature {
                text-align: center;
            }

            &__survey {
                text-align: center;
            }
        }

        .employee-feature {
            display: flex;
            justify-content: center;
            padding: 0 30%;
            align-items: center;
            font-size: 20px;

            svg {
                margin: 0 10px;
                cursor: pointer;
                transition: all 0.3s;

                &:hover {
                    color: var(--text-color);
                }
            }
        }


        .employee-role {
            padding: 8px 10px;
            border-radius: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        td,
        th {
            padding: 15px 20px;
            text-align: left;
            border: 1px solid var(--border-color);
            font-size: 14px;
        }

        thead {
            th {
                border:  1px solid var(--border-color);
                border-top: none;
                border-bottom: none;
                color: var(--text-color);
                position: sticky;
                top: 0;
                background-color: var(--calendar-header-active);
            }

            th:first-child {
                border-left: none;
            }

            th:last-child {
                border-right: none;
            }
        }

        tr {
            td:first-child {
                border-left: none;
            }

            td:last-child {
                border-right: none;
            }
        }

        tr:last-child {
            td {
                border-bottom: none;
            }
        }
    }

    &__create {
        position: fixed;
        bottom: 5%;
        right: 20px;
        background-color: var(--opacity-color);
        padding: 10px;
        border-radius: 100%;
        font-size: 30px;
        color: white;
        cursor: pointer;

        &:hover {
            background-color: var(--text-color);
        }
    }

    .employee-content {
        &-table {
            margin-bottom: 50px;
            position: relative;
        }

        &-chart {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 999;
            width: 100%;
            height: 100%;
            transform: scale(0);
            transition: all 0.3s ease-in-out;

            &.active {
                transform: scale(1);
            }
        }
    }
}