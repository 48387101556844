@import "scss/variables";

.calendar-plan-survey-form {
    max-height: 65vh;
    overflow: scroll;
    padding: 20px;
    font-size: 14px;


    input[type="text"] {
        min-width: 100%;
        text-align: left;
        border-bottom: 1px solid var(--text-color);
    }

    &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        svg {
            position: absolute;
            right: 10px;
            top: 10px;

            &:hover {
                color: var(--text-color);
            }
        }
    }

    &__question-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            margin-right: 10px;
        }
    }

    &__wrap {
        position: fixed;
        box-shadow: var(--box-shadow-active) 0px 5px 15px;
        background-color: var(--body-bg);
        top: 52%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        z-index: 999;
        width: 70vw;

        .toogle-rule {
            margin-left: 10px;
            cursor: pointer;

            &.locked {
                color: red;
            }
        }

        .intro {
            &__wrap {
                position: relative;
            }

            &__action {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                svg {
                    margin-left: 20px;
                    transform: scale(1.2);
                    cursor: pointer;

                    &:hover {
                        color: var(--text-color);
                    }
                }
            }

            &-edit__item {
                label {
                    font-weight: 700;
                }

                textarea {
                    width: 100%;
                    margin: 20px 0;
                    background-color: var(--hover-selector);
                    border: none;
                    outline: none;
                    color: var(--active-text-color);
                }
            }
        }

        .date-input {
            padding: 5px;
            font-size: 16px;
            min-width: 100px !important;
            width: 100px !important;
            border-radius: 8px;
            text-align: center;
            border: 1px solid;
            font-weight: 700;
            color: var(--text-color);
            background-color: var(--bg-color);
            cursor: pointer;
        }
    }

    &__sub-question {
        margin-left: 30px;
    }

    &__answer {
        &-group {
            display: flex;
            margin-left: 10px;
            margin: 10px 0;
            max-width: 100%;
            overflow: scroll;
            padding-bottom: 20px;
            .comment-box {
                width: 100%;
                padding: 20px;
                outline: none;
                border: 1px solid var(--border-color);
                border-radius: 8px;
                margin-top: 20px;
                color: var(--active-text-color);
                background-color: var(--calendar-header-active);
                resize: none;
                height: 30px;
            }

            .checkbox-text {
                &__wrap {
                    display: flex;
                    align-items: center;
                    width: 150px;
                    overflow: scroll;
                    flex-wrap: wrap;
                    label {
                        margin: 0 5px;
                        font-size: 12px;
                        font-weight: bold;
                    }
                }

                &__input {
                    border: 1px solid var(--border-color);
                    width: 30px;
                    padding: 5px;
                    color: var(--active-text-color);
                }

            }

            .date-only {
                margin-bottom: 10px;

                .clear {
                    margin-left: 10px;
                }
            }

            .select-week {
                background-color: var(--calendar-header-active);
                color: var(--active-text-color);
                font-weight: bold;
                outline: none;
                border: none;
                padding: 10px;
                border-radius: 8px;
                min-width: 100px;
            }

            input[type='date'] {
                font-weight: bold;
            }
        }

        &-item {
            margin-right: 15px;
            width: 100%;
        }
    }

    &__submit {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__description {
        margin-top: 5px;
        font-size: 13px;
        font-style: italic;
    }

    &__date {
        &__wrap {
            display: flex;
            align-items: center;
            border-bottom: 1px solid;

            &:last-child {
                border: none;
            }
        }

        &-close {
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                color: var(--text-color);
            }
        }

        &__item {
            display: flex;
            align-items: center;
            margin: 10px 20px;

            label {
                margin-right: 10px;
            }
        }

        &__index {
            font-weight: 700;
            font-size: 14px;
            min-width: 150px;
        }

        &__group {
            display: flex;
            align-items: center;
        }
    }
}

.topic {
    &-title {
        margin: 20px 0;
        padding: 10px 0;
        width: 100%;
        border-bottom: 1px solid;
        // box-shadow: var(--box-shadow-active) 0px 10px 10px -10px;
        box-shadow: var(--box-shadow-active) 0px 25px 20px -20px;
    }
}