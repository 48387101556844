@import "scss/variables";

.calendar-plan-wish-list {
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    h3 {
      color: var(--active-text-color);
    }
  }
  &__content {
    max-height: 500px;
    overflow: scroll;
  }
  &__table {
    width: 100%;
    padding: 20px;
    border-spacing: 0;
    border-collapse: collapse;
    color: var(--active-text-color);
    background-color: var(--calendar-header-active);
    border-radius: 10px;
    padding: 0 !important;

    .text-light {
      color: gray;
      font-weight: 500 !important;
    }

    .text-bold {
      font-weight: bold;
      color: var(--active-text-color);
    }

    .updated-survey {
      display: flex;
      gap: 15px;
      justify-content: center;
      .btn-view {
        cursor: pointer;
      }
    }

    .employee-table {
      &__select {
        width: 50px;
        font-weight: bold;
      }

      &__value {
        min-width: 85px;
        text-align: center;
        font-weight: bold;
      }

      &__name {
        min-width: 150px;
      }

      &__no {
        min-width: 50px;
        max-width: 50px;
      }

      &__date {
        min-width: 150px;
      }

      &__feature {
        text-align: center;
      }

      &__survey {
        text-align: center;
      }
    }

    .employee-feature {
      display: flex;
      justify-content: center;
      padding: 0 30%;
      align-items: center;
      font-size: 20px;

      svg {
        margin: 0 10px;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          color: var(--text-color);
        }
      }
    }

    .employee-send {
      padding: 8px 10px;
      // background-color: #7867ea;
      border-radius: 15px;
      color: white;
      font-weight: 700;
      cursor: pointer;
      transition: all 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: #3a2d93;
      }

      &.red {
        background-color: #a43333 !important;
      }

      &.green {
        background-color: #0db131 !important;
      }
    }

    .employee-role {
      padding: 8px 10px;
      border-radius: 15px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    td,
    th {
      padding: 15px 20px;
      text-align: center;
      border: 1px solid var(--border-color);
      border-radius: 10px;
      font-size: 14px;
    }

    thead {
      th {
        border-top: none;
        color: var(--text-color);
        position: sticky;
        top: 0;
        background-color: var(--calendar-header-active);
      }

      th:first-child {
        border-left: none;
      }

      th:last-child {
        border-right: none;
      }
    }

    tr {
      td {
        font-weight: bold;
      }

      td:first-child {
        border-left: none;
      }

      td:last-child {
        border-right: none;
      }
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }

  &__group__btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    label {
      font-size: 14px;
      border-radius: 8px !important;
    }
  }
}
