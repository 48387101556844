@import 'scss/variables';

.employee-request-question-from {
    position: relative;
    background-color: var(--body-bg);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-width: 300px;
    min-height: 300px;
    max-width: 70%;
    max-height: 80%;
    overflow: scroll;
    padding: 20px 40px;
    transition: all 0.3s;

    &__wrap {
        position: fixed;
        top: 0;
        left: 0;
        background-color: var(--modal-background);
        width: 100vw;
        height: 100vh;
        z-index: 100;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .close {
            display: flex;
            justify-content: flex-end;
        }
    }
    &__content{
        margin-bottom: 10px;
        max-height: 500px;
        overflow: scroll;
    }

    &__table{
        th{
            position: sticky;
            top: -1px;
            background-color: var(--body-bg);
            z-index: 99;
        }
        th,td{
            border: 1px solid var(--border-color);
            padding: 10px;
        }
    }

    &__header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    &__submit{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }


}