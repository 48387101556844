$font-path: '../assets/fonts';

@font-face {
    font-family: SFProDisplayBold;
    src: url('#{$font-path}/SFProDisplay-Bold.ttf') format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: SFProDisplayMedium;
    src: url('#{$font-path}/SFProDisplay-Medium.ttf')format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: SFProDisplayRegular;
    src: url('#{$font-path}/SFProDisplay-Regular.ttf')format('truetype');
    font-display: fallback;
}