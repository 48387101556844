@import 'scss/variables';

.calendar-plan-create-role {

    &__wrap {
        display: flex;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: var(--modal-background);
        top: 0;
        left: 0;
        z-index: 9999;
        justify-content: center;
        align-items: center;
    }

    &__content {
        transition: all 0.3s;
        background-color: var(--body-bg);
        padding: 50px;

        box-shadow: var(--box-shadow-active) 0px 5px 15px;
        max-width: 850px;
        min-width: 550px;
        max-height: 507px;
        display: flex;

        flex-direction: column;
    }

    &__header {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
            margin: 0;
            padding: 0;
            color: var(--active-text-color);
        }

        svg {
            right: 0;
            cursor: pointer;
            font-weight: 900;
            font-size: 18px;
            color: var(--active-text-color);

            &:hover {
                color: var(--text-color);
            }
        }
    }

    &__list {
        margin-top: 20px;
        max-height: 300px;
        overflow-y: scroll;
    }

    &__table {

        tr {
            td:first-child {
                input:first-child {
                    text-align: left;
                }
            }

        }

        th {
            position: sticky;
            top: 0;
            background-color: var(--body-bg);
        }

        th,
        td {
            padding: 15px;
            border: 1px solid var(--border-color);
        }


    }

    &__item {

        input {
            border: none;
            outline: none;
            background-color: transparent;
            font-size: 14px;
            border-bottom: 1px dashed var(--text-color);
            text-align: center;

            &:disabled {
                border: none;
                outline: none;
                background-color: transparent;
            }
        }

        &-description {
            margin: 0 5px;
        }

        &-label {
            color: var(--active-text-color);
            width: 160px;
        }

        &-action {
            svg {
                margin: 0 5px;
                cursor: pointer;

                &:hover {
                    color: var(--active-text-color);
                }
            }
        }
    }

    &__action {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
    }


}