@import 'scss/variables';

.topic-management {
    &__wrap {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: var(--modal-background);
        top: 0;
        left: 0;
        display: flex;
        z-index: 9999;
        justify-content: center;
        align-items: center;
    }

    &__content {
        transition: all 0.3s;
        background-color: var(--body-bg);
        max-width: 550px;
        min-width: 550px;
        max-height: 350px;
        display: flex;
        flex-direction: column;
        overflow: scroll;

        &-wrap {
            background-color: var(--body-bg);
            box-shadow: var(--box-shadow-active) 0px 5px 15px;
            padding: 50px;
        height: 450px;
        overflow: hidden;
        }
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--active-text-color);

        svg {
            color: var(--text-color);

            &:hover {
                color: var(--active-text-color);
            }
        }
    }

    &__item {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid;

        &:last-child {
            border: none;
        }

        svg {
            margin: 0 15px;
        }
    }

    &__footer {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }


}

.topic-edit__group {
    display: flex;
    flex-direction: column;

    label {
        margin: 20px 0;
    }

    input {
        background-color: var(----calendar-plan-number);
        color: var(--active-text-color);
        border: none;
        outline: none;
        padding-top: 10px 0;
        min-width: 300px;
        border-bottom: 1px dashed;
        padding-bottom: 5px;
    }
}