@import 'scss/variables';

.progress-bar {
    &__wrap {
        min-width: 200px;
        height: 12px;
        border: 1px solid;
        border-radius: 10px;
        width: 90%;
        .progress {
            background-color: var(--btn-color);
            height: 12px;
            transition: all 0.3s;
            border-radius: 10px;
            transition: all 1s;
        }
    }


}

@media only screen and (max-width: 1300px) {
    .progress-bar {
        &__wrap {
            height: 10px;

            .progress {
                height: 10px;
            }
        }
    }
}