.App[data-theme="dark"] {
    --body-bg: #15181a;
    --sidebar-bg: #15181a;
    --card-bg: #15181a;
    --footer-bg: #15181a;
    --aside-menu-bg: #15181a;
    --header-bg: #181a1c;
    --navbar-bg: transparent;
    --black-text: #181a1c;
    --text-color: #afafaf;
    --active-text-color: #ffff;
    --btn-color: #454ea5;
    --btn-hover-color: #5864cb;
    --secondary-color: #161819;
    --active-selector: #afafaf;
    --hover-selector: #303132;
    --opacity-color: #32333371;
    --border-color: #323333bc;
    --filter-color: #1e1e1edf;
    --timer-color: #0ac4ba;
    --person-color: #ff4081;
    --btn-hover-gray: #7f7f7f;
    --gray: #af9d9d;
    --dark-blue: #0000ff;
    --green: #3fbd3f;
    --blue: #2098c0;
    --red: #f44336;
    --yellow: #c5b522;
    --orange:#FA8128;
    --dark-orange:#bf8c1f;
    --bg-form-whitesmoke: whitesmoke;
    --box-shadow: #7c7c7f33;
    --box-shadow-active: #bebed633;
    --striped-bg: #363434;
    --calendar-header-item: #1e1e1edf;
    --calendar-header-active: #26282a;
    --calendar-header-border-active: #7f7f7f;
    --modal-background: #15181ab3;
    --calendar-plan-number: #2f2f2f;
}

.App[data-theme="light"] {
    --body-bg: #fafafa;
    --sidebar-bg: #fafafa;
    --card-bg: #fafafa;
    --footer-bg: #fafafa;
    --aside-menu-bg: #fafafa;
    --header-bg: #ffff;
    --navbar-bg: transparent;
    --text-color: #999999;
    --active-text-color: #181a1c;
    --btn-color: #454ea5;
    --btn-hover-color: #5864cb;
    --secondary-color: #f1f1f1;
    --active-selector: #ffffff;
    --hover-selector: #e6e5e5;
    --opacity-color: #80828271;
    --bg-form-whitesmoke: rgb(225, 222, 222);
    --border-color: #80828271;
    --filter-color: #f6f6f6;
    --timer-color: #0ac4ba;
    --person-color: #ff4081;
    --btn-hover-gray: #7f7f7f;
    --gray: #4b4848;
    --dark-blue: #0000ff;
    --green: #3fbd3f;
    --blue: #2098c0;
    --red: #f44336;
    --yellow: #c5b522;
    --orange: #FA8128;
    --box-shadow: #64646f33;
    --box-shadow-active: #00000059;
    --striped-bg: #dbdfe3;
    --black-text: #181a1c;
    --calendar-header-item: #fafafa;
    --calendar-header-border-active: #afafaf;
    --calendar-header-active: #f1f1f1;
    --modal-background: #7e7e7eb3;
    --calendar-plan-number: #e1e1e1;
}

$blur-color: #7f7f7f;
$highlight-color: #0ac4ba;

$font-bold: SFProDisplayBold;
$font-medium: SFProDisplayMedium;
$font-regular: SFProDisplayRegular;

$sidebar-width: 300px !default;
$mobile-sidebar-width: 320px !default;
