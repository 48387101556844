@import "scss/variables";

.calendar-plan-content-table {
  background-color: var(--calendar-header-active);
  border-radius: 10px;
  transition: all 0.3s;
  margin-bottom: 20px;

  &__wrap {
    max-width: 60vw;
    overflow: scroll;
    margin-bottom: 10px;
    max-height: 400px;
  }

  .text-center {
    text-align: center;
    min-width: 95px;
  }

  .sticky {
    position: sticky;
    left: -1px;
    background-color: var(--calendar-header-active) !important;
    text-align: center;

    &-top-week {
      top: 0px !important;
      background-color: var(--calendar-header-active);
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      height: 1px;
      background-color: #000;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }

    &-top-header {
      top: 30px !important;
      background-color: var(--calendar-header-active);
    }
  }

  .sticky-secondary {
    left: 52px;
    max-width: 200px;
    min-width: 200px;
    z-index: 99;
  }

  .sticky-third {
    left: 261px;
    min-width: 100px;
  }

  .sticky-fifth {
    left: 560px;
    min-width: 100px;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: var(--border-color);
    }

    &::before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: var(--border-color);
    }
  }

  .sticky-fouth {
    left: 401px;
    min-width: 130px;
  }

  .hide {
    position: absolute;
    transform: translateX(-10000px);
  }

  .weekend {
    color: #dc056d;
  }

  .dayOff {
    background-color: #ad892e2a;
  }

  .value {
    font-size: 13px;
    text-align: center;
    transition: all 0.3s;
  }

  &-input {
    border: none;
    background: transparent;
    text-align: center;
    color: var(--text-color);
  }

  .unassigned {
    font-weight: bold;
  }

  .job-percentage {
    min-width: 130px;
  }

  .summary-cell-calendar {
    display: flex;
    flex-direction: column;
    &__item {
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid #ad892e2a;
      &-title {
        margin-right: 5px;
      }
    }
  }

  td,
  th {
    padding: 5px 20px;
    text-align: left;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    border-radius: 0;
    transition: all 0.3s;
  }

  .no {
    width: 10px;
    min-width: none !important;
    z-index: 99;
  }

  .select {
    width: 10px;
    min-width: none !important;
    z-index: 9999999;
  }

  .week-head {
    position: sticky;
    top: 0px;
    z-index: 9999999;
  }

  .weekDay {
    position: sticky;
    top: 30px;
    z-index: 9999999;
  }

  thead {
    // background-color: white;

    th {
      border-top: none;
      padding: 10px 20px;
      color: var(--text-color);
      // height: px;
      z-index: 20;
    }

    th:first-child {
      border-left: none;
    }

    th:last-child {
      border-right: none;
    }
  }

  tr {
    td:first-child {
      border-left: none;
    }

    td:last-child {
      border-right: none;
    }
  }

  tr:last-child {
    td {
      border-bottom: none;
    }
  }

  tfoot {
    position: sticky;
    inset-block-end: 0;
    background-color: var(--calendar-header-active) !important;
    bottom: -1px;
    font-weight: bold;
  }
}
