.kalender-plan-content {
    width: 100%;
    padding: 30px;

    h3 {
        color: var(--active-text-color);
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;


    }

    &__action {
        display: flex;
        justify-content: center;
        align-items: center;

        &-select {
            margin-left: 10px;
            color: var(--active-text-color);
            padding: 10px;
            border-radius: 5px;
            border: 1px solid var(--border-color);
            background-color: var(--calendar-header-item);
            color: var(--active-text-color);
            min-width: 100px;
        }

        &-label {
            margin-right: 10px;
            font-weight: bold;
        }

        &-group {
            margin-left: 40px;
        }


    }

}

.kalender-plan__feature {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
        margin: 0 10px;
    }
}