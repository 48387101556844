@import 'scss/variables';

.filter-department {
    &__wrap {
        max-height: 25vh;
        margin-top: 20px;
        overflow: scroll;
        max-width: 450px;
        padding-left: 5px;
    }

    &__group {
        width: 100%;
    }

    &__list {
        margin-left: 20px;
        max-height: 200px;
        overflow-y: scroll;

        &::-webkit-scrollbar-thumb {
            background-color: var(--active-text-color);
        }

        &::-webkit-scrollbar-track {
            background-color: var(--body-bg);
        }
    }

    &__item {
        margin: 10px 0;
        font-size: 13px;
        cursor: pointer;
    }


    &__head {
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--active-text-color);
        font-size: 14px;
        cursor: pointer;
        margin-top: 10px;

        &-icon {
            font-size: 24px;
            color: var(--active-text-color);
            transition: all 0.3s;

            &.active {
                transform: rotate(180deg);
            }
        }
    }

    &__checkbox {
        transform: scale(1.5);
        margin-right: 10px;

        &:checked {
            accent-color: var(--btn-color);
        }
    }
}