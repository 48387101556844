@import 'scss/variables';


.employee-time-off-chart {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90%;

    &__content {
        margin-top: 20px;
    }

    &__wrap {
        background-color: var(--calendar-header-active);
        padding: 0 20px;
        height: 100%;
        overflow: hidden;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__select {
        padding: 8px;
        border-radius: 10px;
        background-color: transparent;
        color: var(--active-text-color);
        margin-bottom: 20px;
    }
}