@import "scss/variables";

.wish-list-survey {
  width: 100%;
  height: 100vh;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;

    .complete-survey {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      color: var(--text-color);
      background-color: var(--bg-form-whitesmoke);
      gap: 20px;
      padding: 30px;
      border-radius: 10px;
      &__footer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        .return-btn {
          font-size: 18px;
          color: var(--bg-color) !important;
          cursor: pointer;
        }
      }
    }
    .submit-btn {
      align-self: flex-end;
    }
  }

  &__content {
    border-radius: 10px;
    padding: 50px;

    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: var(--calendar-header-active);
    gap: 20px;
  }
}
