@import 'scss/variables';

.calendar-staff-table {
    background-color: var(--calendar-header-active);
    border-radius: 10px;
    overflow: scroll;
    max-width: 100%;

    .record-name {
        font-weight: bold;
    }

    .record-id {
        width: 20px;
        text-align: center;
    }

    .record-time {
        width: 300px;
    }

    .record-action {
        width: 100px;
    }

    td,
    th {
        padding: 15px 20px;
        text-align: left;
        border: 1px solid var(--border-color);
        border-radius: 10px
    }

    td {
        color: var(--active-color-text);
    }

    thead {
        th {
            border-top: none;
            color: var(--text-color);
            z-index:9;
        }

        th:first-child {
            border-left: none;
        }

        th:last-child {
            border-right: none;
        }
    }

    tr {
        td:first-child {
            border-left: none;
        }

        td:last-child {
            border-right: none;
        }
    }

    tr:last-child {
        td {
            border-bottom: none;
        }
    }

    thead {
        .time__wrap {
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;

            input {
                border: none;
                width: 100%;
                outline: none;
                background: transparent;
                text-align: center;
                color: var(--text-color);
                font-weight: bold;
                font-size: 16px;
            }
        }
    }


    .time {
        margin: 0 20px;
    }



    &__action {
        svg {
            margin: 0 10px;
            cursor: pointer;

            &:hover {
                color: var(--text-color);
            }
        }
    }

    tr:nth-child(even) {
        background-color: transparent ;
    }
}