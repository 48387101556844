@import "scss/variables";

.pagination {
    &__wrap {
        display: flex;
        // margin-top: 20px;
        align-items: center;
    }

    &-group {
        display: flex;
        align-items: center;
    }

    &-pre,
    &-next {
        color: var(--active-text-color);
        font-size: 110%;
    }

    &-item {
        margin: 0 10px;
        cursor: pointer;
        transition: all 0.3s;
        font-weight: 500;

        &:hover {
            color: var(--active-text-color);
        }

        &.active {
            color: var(--active-text-color);
        }
    }
}
