@media only screen and (max-width: 1300px) {
    body {
        *{
            font-size: 98%;
        }
        .custom-btn {
            padding: 3px;
        }

    }

}

@media only screen and (min-width: 1990px) {
    body {
        * {
            font-size: 103%;
        }

        .custom-btn {
            padding: 3px;
        }

    }

}

.app-body {
    min-height: 100vh;
    padding-top: 80px;
    padding-left: 120px;
    padding-right: 50px;
    transition: all 0.1s ease;

    &.filter-active {
        padding-left: 400px;

    }
}

.ignore-filter {
    padding-left: 0 !important;
}

@media only screen and (max-width: 1080px) {
    .healthcare-planning {
        display: none;
    }

    .app-body.filter-active {
        padding-left: 120px;
    }
}