@import 'scss/variables';

.upload-staff-plan {
    &__wrap {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999999;
        background-color: var(--modal-background);
        color: var(--active-text-color);
    }

    &__form {
        background-color: var(--secondary-color);
        min-height: 200px;
        width: 50%;
        position: relative;
        padding: 20px;
        border-radius: 8px;

        &-group {
            display: flex;

        }

        &-item {
            margin: 10px;
            display: flex;
            align-items: center;

            label {
                margin-right: 10px;
                font-weight: bold;
            }

            input[type='date'] {
                padding: 5px 10px;
                font-size: 16px;
                font-weight: 700;
                border-radius: 8px;
                text-align: center;
                border: 1px solid;
                background-color: var(--calendar-header-itemr);
                cursor: pointer;
            }

            input[type='text'] {
                border: none;
                width: 100%;
                padding:  10px;
                border-radius: 8px;
                outline: none;
                background-color: var(--calendar-header-item);
                color: var(--active-text-color);
            }
        }

        &-submit {
            display: flex;
            justify-content: center;
            margin: 20px 0 10px;
        }
    }

    &__close {
        position: absolute;
        right: 10px;
        top: 10px;

        &:hover {
            color: var(--btn-hover-gray);
        }
    }
}