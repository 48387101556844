@import './variables';



.App {
    position: relative;
    background-color: var(--body-bg);
    color: var(--text-color);
    overflow-x: hidden;

    a:-webkit-any-link {
        color: var(--text-color);
        cursor: pointer;
        text-decoration: none;
    }

    li {
        list-style: none;
    }

    .app-footer {
        z-index: 3;
    }

    .chart__wrap {
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 10px 20px;
        background-color: var(--filter-color);

        .chart__main {
            position: relative;
            margin-top: 50px;
        }

        .chart__title {
            font-size: 24px;
            margin: 20px 0;
        }

        .date-picker {
            &__wrap {
                display: flex;


            }

            &__group {
                display: flex;
                align-items: center;
                margin-right: 20px;

            }

            &__title {
                margin-right: 10px;
            }
        }

    }

    .custom-btn {
        background-color: var(--btn-color);
        color: white;
        outline: none;
        border: none;
        padding: 10px 25px;
        border-radius: 20px;
        cursor: pointer;
        font-weight: 600;
        transition: all 0.3s;

        &:hover {
            color: white;
            background-color: var(--btn-hover-gray);
        }

        &.none-bg {
            background-color: transparent;
            border: 1px solid var(--active-text-color);
            color: var(--active-text-color);
        }

        &.none-outline {
            border: none;


        }

    }


    .hide-element {
        display: none;
    }

    .square-btn {
        border-radius: 8px;
        padding: 6px 25px;

    }

    button:disabled {
        background-color: var(--btn-hover-gray);
        cursor: not-allowed;
    }

    input[type="time"]::-webkit-calendar-picker-indicator {
        filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
    }

    input {
        color: var(--text-color);
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    table {
        border-spacing: 0;

        td,
        th {
            border-collapse: collapse;
        }
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .disable {
        cursor: not-allowed;
        background-color: #999999;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        background-color: var(--border-color);
        width: 100vw;
        height: 100vh;
        z-index: 99999999;
       
    }
    .center-block{
        display: flex;
            justify-content: center;
            align-items: center;
    }
}



