@import "scss/variables";

.send-survey {
    &-form {
        &__wrap {
            background-color: var(--body-bg);
            z-index: 9999;
            border-radius: 20px;
            padding: 20px 40px;
        }

        &__content {
            position: relative;
            background-color: var(--body-bg);
            border-radius: 20px;
            width: 70vw;
            overflow: scroll;
            padding: 20px 40px;
            transition: all 0.3s;
            max-height: 500px;
            overflow: scroll;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            svg:hover {
                color: var(--active-text-color);
            }
        }

        &__total {
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;

            &-emp {
                margin-right: 20px;
            }
        }

        &__submit {
            display: flex;
            justify-content: space-between;
        }
        &__next-btn{
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
        }

        &__footer {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            padding: 10px 0;
            margin-bottom: 20px;
           

            .loading {
                background-color: gray !important;
            }
        }
    }

    &-table {
        transition: all 0.3s;

        td,
        th {
            border: 1px solid var(--border-color);
            padding: 10px;
        }

        tr {
            td:first-child {
                text-align: center;
                width: 40px;
            }
        }
    }
}

.block-question-table {

    td,
    th {
        border: 1px solid var(--border-color);
        padding: 10px;

        &:first-child {
            text-align: center;
        }
    }

    thead {
        th {
            position: sticky;
            top: -25px;
            background-color: var(--body-bg);
            z-index: 9;
        }
    }
}