@import "scss/variables";

@media only screen and (max-width: 1024px) {
    .calendar-plan-header {
        &__wrap {

            .menu-title {
                display: none;
            }
        }
    }
}

.calendar-plan-header {
    &__wrap {
        position: relative;
        padding-top: 30px;
        display: flex;
        width: 100%;
        justify-content: center;
        // padding-right: -50px;
        // border-bottom: 1px solid ;
    }

    &__item {
        display: flex;
        padding: 20px;
        cursor: pointer;
        transition: all 0.3s;
        font-size: 20px;
        border-radius: 5px 5px 0 0;
        background-color: var(--calendar-header-active);
        max-width: 250px;
        max-height: 96px;
        border: 1px solid var(--border-color);
        width: 15%;
        max-width: 15%;
        min-width: 10%;
        justify-content: center;
        text-align: center;
        border-bottom: none;
        border-bottom: 1px solid;

        .menu-title {
            font-size: 18px;
            margin-left: 10px;
            font-weight: 700;
            color: var(--text-color);
            transition: all 0.3s;
        }

        svg {
            background-color: var(--active-text-color);
            padding: 10px;
            color: white;
            margin-bottom: 10px;
            border-radius: 5px;
            font-size: 23px;
        }

        &:hover {
            background-color: var(--filter-color);

            .menu-title {
                color: var(--active-text-color);
            }

            font-weight: 700;
        }

        &.active {
            // box-shadow: var(--box-shadow-active) 0px 5px 15px;
            border-radius: 5px 5px 0 0;
            background-color: var(--body-bg);
            border: 1px solid var(--calendar-header-border-active);
            // border-bottom: 2px solid #fff;
            z-index: 1;
            position: relative;

            .menu-title {
                color: var(--active-text-color);
            }

            &::after {
                content: "";
                position: absolute;
                height: 4px;
                width: 100%;
                background-color: var(--body-bg);
                bottom: -4px;
                left: 0;
            }
        }
    }
}