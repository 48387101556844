@import 'scss/variables';

.custom-tooltip {
    &__wrap {
        min-height: 100px;
        min-width:200px;
        position: absolute;
        background-color: var(--header-bg);
        transform: translate(-110%, -30%);
        top: 30%;
        display: flex;
        align-items: center;
        z-index: 1;
        border-radius: 6px;
        overflow-y: scroll;
        z-index: 4;
        .tooltip-text {
            padding: 10px;
            max-height: 200px;
        }

        .tooltip .tooltip-text::after {
            content: " ";
            position: absolute;
            top: 50%;
            left: 100%;
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent transparent var(--header-bg);
        }
    }

}