@import 'scss/variables';

.header__wrap {
    min-height: 7vh;
    background-color: var(--header-bg);
    position: fixed;
    width: 100vw;
    z-index: 999999;
}

.header__content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    .header__logo {
        display: flex;
        align-items: center;
        width: 30%;

        .small-logo {
            width: 40px !important;
        }

        .logo {
            margin-right: 10px;
        }

        .healthcare-planning {
            font-size: 18px;
        }
    }

    .header__right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 20%;

        .profile-menu {
            background-color: var(--sidebar-bg);
            box-shadow: var(--box-shadow-active) 0px 7px 29px 0px;
            min-width: 150px;
            min-height: 50px;
            position: absolute;
            right: 0;
            top: 100%;
            padding: 20px;
            border-radius: 6px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            transform: scale(0);
            transition: all 0.3s;

            &__wrap {
                margin-left: 20px;
                cursor: pointer;
                font-size: 20px;
                color: var(--active-text-color);
                transition: all 0.3s;
                position: relative;

                &:hover {
                    svg {
                        font-weight: 700;
                        transform: scale(1.3);
                    }

                    .profile-menu {
                        transform: scale(1);
                    }

                }
            }

            &-item {
                transition: all 0.3s;
                padding: 20px 0;
                text-align: center;

                &:hover {
                    text-decoration: underline;

                }
            }



        }

        .lang__wrap {
            display: flex;

            &:hover {
                .lang-option__list {
                    display: block;
                }
            }

            .lang-value {
                position: relative;
            }

            .lang-icon {
                margin-right: 5px;
            }

            .lang-option__list {
                position: absolute;
                left: -8px;
                display: none;
            }

            .lang-option__item {
                padding: 8px 8px;
                background-color: var(--secondary-color);

                &:hover {
                    background-color: var(--hover-selector);
                    cursor: pointer;
                    color: var(--active-text-color);
                }
            }
        }

    }

    .header__middle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;

        .nav-item {
            margin: 0 20px;
            font-weight: 400;
            transition: all 0.3s;

            &.active,
            &:hover {
                font-weight: 400;
                color: var(--active-text-color);
            }
        }
    }
}

@media only screen and (max-width: 1080px) {
    .healthcare-planning {
        display: none;
    }


}