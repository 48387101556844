@import 'scss/variables';

.addMoreRow {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
    width: fit-content;
    color: var(--active-text-color);

    &__wrap {
        position: relative;
        width: fit-content;
    }



    &:hover {
        .add-task {
            text-decoration: underline;
        }

        .add-task__wrap {
            display: flex;
        }
    }

    p {
        font-size: 12px;
        margin-left: 5px;


    }

}

.add-task {
    &__wrap {
        position: absolute;
        display: flex;
        align-items: center;
        transform: translate(20px, 20%);
        z-index: 99999;
        flex-direction: column;
        background-color: var(--calendar-header-active);
        border: 1px solid var(--border-color);
        border-radius: 5px;
        max-height: 250px;
        overflow: scroll;
        bottom: -100%;
        min-width: 500px;
    }

    &__item {
        font-size: 14px;
        padding: 8px 0;
        cursor: pointer;
        border-bottom: 1px solid var(--border-color);
        width: 100%;
        display: flex;
        justify-content: center;
        font-weight: 700;
        transition: all 0.3s;
        color: var(--text-color);
      
        &-row {
            cursor: pointer;

            &:hover {
                    background-color: var(--hover-selector)!important;
                .add-task__item {
                    color: var(--active-text-color);
                }


            }
        }

        &:last-child {
            border: none;
        }

        &:hover {
            color: var(--active-text-color);
        }
    }

    &__table {
      
        th {
            text-align: center ;
            padding: 5px 0 ;
            font-size: 13px;
            position: sticky;
            top: 0;
            background-color: var(--calendar-header-active);
            min-width: 70px;
        }

        
    }
}