@import 'scss/variables';

.kalender-plan-content__wrap {
    box-sizing: border-box;

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        background-color: var(--border-color);
        width: 100vw;
        height: 100vh;
        z-index: 99999999;
    }

}

.kalender-plan-content-table {
    background-color: var(--calendar-header-active);
    border-radius: 10px;
    transition: all 0.3s;

    .copy-btn {
        margin-left: 10px;
        cursor: pointer;
        font-weight: 700;

        &.lock {
            color: red;
        }

        &.active {
            color: var(--green);
        }
    }

    &__wrap {
        overflow: scroll;
        behavior: smooth;
        margin-bottom: 10px;
        max-height: 500px;
        min-height: 350px;
        margin-top: 20px;


    }

    .text-center {
        text-align: center;
        min-width: 95px;
    }

    .sticky {
        position: sticky;
        left: -1px;
        background-color: var(--calendar-header-active);
        text-align: center;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            height: 1px;
            background-color: #000;
        }

        &::before {
            left: 0;
        }

        &::after {
            right: 0;
        }
    }

    .sticky-secondary {
        // left: 60px;
        max-width: 300px;
        min-width: 250px;


    }

    .sticky-third {
        left: 300px;
        min-width: 100px;
    }

    .sticky-fifth {
        left: 630px;
        min-width: 100px;


    }

    .sticky-sixth {
        left: 800px;
        min-width: 100px;

        &::after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 1px;
            background-color: var(--border-color);
        }

        &::before {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 1px;
            background-color: var(--border-color);
        }
    }

    .sticky-seventh {
        left: 800px;
        min-width: 100px;

        &::after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 1px;
            background-color: var(--border-color);
        }

        &::before {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 1px;
            background-color: var(--border-color);
        }
    }



    .sticky-fouth {
        left: 491px;
        min-width: 130px;
    }

    .role-detail {
        visibility: collapse;
        transition: all 0.3s;
        margin-top: 10px;

        &.active {
            visibility: initial;
        }
    }

    .collapse {
        width: 100%;
        cursor: pointer;
        position: absolute;
        bottom: 0;
        left: 0;

        &:hover {
            background-color: var(--border-color);
        }
    }

    .hide {
        position: absolute;
        transform: translateX(-10000px);
    }

    .working-date {
        background-color: #5187182a;
    }

    .weekend {
        color: #dc056d;
    }

    .holiday {
        background-color: #5f98e92a;
    }

    .dayOff {
        background-color: #ad892e2a;
    }

    .has-group {
        background-color: #c43cbf2a;
    }

    .value {
        font-size: 14px;
        text-align: center;
        transition: all 0.3s;

    }

    .name {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }

    &-input {
        border: none;
        background: transparent;
        text-align: center;
        color: var(--text-color);
        cursor: pointer;
    }

    &-hard-input {
        cursor: pointer;
    }

    .unassigned {
        font-weight: bold;
    }

    .job-percentage {
        min-width: 130px;
    }

    .summary-header {
        padding: 20px 0;
    }

    .summary-cell-kalender {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        margin-bottom: 10px;

        &__item {
            margin: 10px;

            &-title {
                margin-right: 5px;
                font-size: 11px;
            }
        }
    }

    td,
    th {
        padding: 5px 20px;
        text-align: left;
        border: 1px solid var(--border-color);
        border-radius: 10px;
        border-radius: 0;
        transition: all 0.3s;
    }

    .no {
        width: 10px;
        min-width: none !important;
    }

    thead {
        th {
            border-top: none;
            padding: 10px 20px;
            text-align: center;
            z-index: 20;
        }

        th:first-child {
            border-left: none;

        }

        th:last-child {
            border-right: none;
        }
    }

    tr {
        height: 30px;

        td:first-child {
            border-left: none;
        }

        td:last-child {
            border-right: none;
        }
    }

    tr:last-child {
        td {
            border-bottom: none;
        }
    }

    .tfoot {
        position: sticky;
        bottom: 0;
        background-color: var(--calendar-header-active);
        bottom: -1px;
        font-weight: bold;

    }


    thead {
        position: sticky;
        background-color: var(--calendar-header-active);
        font-weight: bold;
        z-index: 99;
        font-weight: bold;


        tr {
            background-color: var(--calendar-header-active);
        }

        tr:first-child {
            position: sticky;
            inset-block-start: 0;
            top: -1px;

        }

        tr:nth-child(2) {
            position: sticky;
            top: 29px
        }

    }


    .not-valid-shift {
        color: red;
    }

    .summary-cell {
        z-index: 999;
    }

    tr {
        transition: all 0.1s;
    }

    tr:hover {
        background-color: var(--hover-selector) !important;

        td,
        th {
            background-color: var(--hover-selector) !important;
        }

    }

    .hiden-column {
        display: none;
    }
}

.kalender-plan-content__header-function {
    button {
        margin-left: 10px;
    }
}

.kalendar-legend {
    height: 30px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;

    .legend {
        width: 40px;
        height: 20px;
        margin-right: 10px;

        &-wrap {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: var(--active-text-color);
            font-weight: 700;
        }
    }

    .working-date {
        background-color: #5187182a;
    }


    .holiday {
        background-color: #5f98e92a;
    }

    .dayOff {
        background-color: #ad892e2a;
    }
           .has-group {
               background-color: #c43cbf2a;
           }

}