@import "scss/variables";
@import "scss/mixins";

.calendar-view-plan-wish-list {
  &__wrap {
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--modal-background);
    width: 100vw;
    height: 100vh;
    z-index: 100;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    span {
      font-size: 22px;
    }
  }
  &__content {
    position: relative;
    background-color: var(--body-bg);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-width: 200px;
    max-width: 70%;
    max-height: 80%;
    overflow: scroll;
    padding: 20px 40px;
    transition: all 0.3s;

    .pagination-btn {
      width: 100%;
      display: flex;
      gap: 10px;
      user-select: none;
    }

    .select-wish-title {
      font-size: 24px;
      color: var(--bg-form-whitesmoke);
      font-weight: 600;
    }

    &__body {
      width: 90%;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .select-wish-sub-title {
        color: var(--bg-form-whitesmoke);
      }
      .select-wish-upload__container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 30px;
        border-radius: 8px;

        @include Responsive(MD) {
          flex-direction: column;
          align-items: start;
        }

        .input-file-btn {
          width: 140px;
          text-align: center;
        }

        .selected-file-name {
          min-width: 150px;
          text-align: center;
        }
      }
      .select-wish-file {
        color: var(--bg-form-whitesmoke);
        border: dashed 3px var(--bg-form-whitesmoke);
        border-radius: 10px;
        padding: 30px 30px;

        cursor: pointer;
        &::-webkit-file-upload-button {
          width: 200px;
          padding: 10px 50px;
          background-color: var(--btn-color);
          border: none;
          color: var(--bg-form-whitesmoke);
          border-radius: 8px;
          font-size: 18px;
          margin-right: 50px;
          font-weight: 600;
          cursor: pointer;
        }
      }
      .upload-btn {
        background-color: var(--btn-color);
        border: none;
        border-radius: 8px;
        padding: 12px 20px;
        color: var(--text-color);
        cursor: pointer;

        &:hover {
          color: var(--active-text-color);
        }
      }
    }

    .wish-list-table {
      border-radius: 10px;
      &__container {
        width: 100%;
        background-color: var(--calendar-header-active);
        overflow-y: scroll;
        border-radius: 10px;
      }

      &__table {
        transition: all 0.3s;
        padding: 50px;
        background-color: var(--calendar-header-active);
        border-radius: 10px;
        &__no {
          min-width: 50px;
        }

        &__name {
          min-width: 250px;
          background-color: var(--calendar-header-active);
          z-index: 100000;
          top: 0px;
        }
        &__date {
          min-width: 140px;
          z-index: 99999 !important;
          top: -1px;
        }

        &__days {
          min-width: 140px;
        }
        td,
        th {
          border: 1px solid var(--border-color);
          padding: 5px 0;
          text-align: center;
          font-size: 16px;
          position: sticky;
          left: 0px;
          background-color: var(--calendar-header-active);
        }

        td {
          font-size: 14px;
          z-index: 100;
          padding: 10px 0;
        }

        tr {
          td:first-child {
            text-align: center;
            width: 40px;
          }
        }

        &__value {
          z-index: 1 !important;
        }
      }

      &__footer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
