@import 'scss/variables';

.create-staff-plan-form {
    &__wrap {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: var(--modal-background);
        top: 0;
        left: 0;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;    
        input {
            color: var(--active-text-color);
        }

       
    }

    &__header {
        position: relative;
        color: var(--active-text-color);

        svg {
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;
            color: var(--active-text-color);
            font-size: 18px;

            &:hover {

                color: var(---text-color);
            }
        }
    }

    &__content {
        background-color: var(--body-bg);
        padding: 30px;
        width: 80vw;
        height: 80vh;
        overflow: scroll;
    }

    &__chart {
        max-width: 80vw;
        overflow: scroll;
    }

    &__info {
        &-group {
            display: flex;
            align-items: center;
            margin-top: 10px;

            input {
                padding: 8px;
                border: 1px solid var(--border-color);
                border-radius: 5px;
                background-color: transparent;
            }

            input[type=date] {
                color: var(--active-text-color);
                font-weight: 700;
                border-radius: 8px;
            }
                     

            &__input {
                width: 300px;
                margin-right: 30px;
            }

            &__item {
                margin-right: 30px;
            }

            &__label {
                margin-right: 10px;
                color: var(--active-text-color);
                font-weight: bold;
            }
        }

        .plan-name {
            color: var(--active-text-color);
            font-weight: bold;
        }
    }

    &__submit {
        display: flex;
        justify-content: flex-end;

        button {
            border-radius: 5px !important;
            margin: 0 10px;
        }
    }
}