@import 'scss/variables';

.filter__wrap {
    position: fixed;
    top: 5vh;
    padding: 70px 20px;
    background-color: var(--filter-color);
    height: 100vh;
    min-width: 30px;
    display: flex;
    justify-content: center;
    z-index: 8;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
        background-color: var(--hover-selector);
    }

    .filter-item {
        font-size: 24px;
        margin: 10px 0;
        position: relative;

        h3 {
            margin-bottom: 20px;
        }

        &-group {
            margin: 20px 0;
        }
    }

    .count-filter {
        position: absolute;
        bottom: -10px;
        right: -60%;
        font-size: 12px;
        font-weight: 700;
        height: 20px;
        width: 20px;
        line-height: 20px;
        border: 1px solid var(--person-color);
        background-color: var(--person-color);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        color: #fff;
    }

    &.active {
      max-width: 300px;

        &:hover {
            background-color: var(--filter-color);
        }

        .filter-title {
            font-size: 24px;
            color: var(--active-text-color);
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .filter-search {
            &-group {
                display: flex;
                align-items: center;
                border: 2px solid var(--text-color);
                padding: 10px 10px 10px 5px;
                font-weight: 900;
                margin-bottom: 10px;
                border-radius: 5px;
                font-size: 20px;
            }

            &-icon {
                margin-right: 5px;
                font-weight: 700;
            }

            &-input {
                border: none;
                outline: none;
                background-color: transparent;
                color: var(--text-color);
            }
        }
    }
}

.filter-close {
    transition: all 0.2s;

    &:hover {
        color: var(--text-color)
    }
}

@media only screen and (max-width: 1280px) {
    .filter__wrap {
        .filter-item{
            svg{
                font-size: 20px!important;
            }
        }
        .count-filter {
            span{
                display: none;
            }
            bottom: -5px;
           width: 3px;
           height: 3px;
        }

    }

}