@import 'scss/variables';

.task-list-content {

    &__header {
        display: flex;
        justify-content: space-between;

        h1 {
            color: var(--active-text-color);
            font-size: 25px;
        }
    }

    &__wrap {
        max-height: 500px;
        overflow: scroll;
    }

    &__feature {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        button {
            font-size: 16px;
            border-radius: 5px !important;
            margin: 0 10px;

            &.none-outline {
                border: none;

                &:hover {
                    color: var(--active-text-color);
                }

                span {
                    margin-left: 10px;
                    color: var(--text-color);

                    svg {
                        margin-right: 10px;
                    }

                    &:hover {
                        color: var(--active-text-color);
                    }
                }

                svg {
                    &:hover {
                        color: var(--active-text-color);
                    }
                }
            }
        }


    }
}