@import 'scss/variables';

.calendar-staff {
    &__wrap {
        width: 100%;

        .switch__wrap {
            font-size: 14px;
            font-weight: 700;
            width: 250px;
            height: 50px;
        }
    }



    &__content {
        width: 100%;
        min-height: 100vh;
        overflow: scroll;
        margin-bottom: 50px;
        margin-top: 30px;

    }

    &__create {
        position: fixed;
        bottom: 5%;
        right: 20px;
        background-color: var(--opacity-color);
        padding: 10px;
        border-radius: 100%;
        font-size: 30px;
        color: white;
        cursor: pointer;

        &:hover {
            background-color: var(--text-color);
        }

        &.plan {
            bottom: 13%;
        }
    }


    &__feature {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        h1 {
            margin: 0;
            padding: 0;
            color: var(--active-text-color);
        }


    }

    &__table {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;
        color: var(--active-text-color);

        tr:nth-child(even) {
            background-color: var(--striped-bg);
        }

        th {
            background-color: var(--striped-bg);
            position: sticky;
            top: -35px;
        }

        td,
        th {
            border: 1px solid var(--border-color);
            text-align: center;
            padding: 20px;
            border-collapse: collapse;
            font-size: 14px;
        }

        &-feature {
            width: 100px;
        }

        .task-names {
            display: flex;
            justify-content: center;
            width: fit-content;
            text-align: center;
            margin: 0 auto;

            input[type="time"]::-webkit-calendar-picker-indicator {
                display: none;
            }
        }

        .task__feature {
            svg {
                margin: 5px 10px;


            }
        }

        .task_input {
            border: none;
            outline: none;
            background-color: transparent;
            text-align: center;
            width: 100%;
            color: var(--active-text-color);
        }
    }

    &__plan-list {
        margin-top: 20px;
    }

}


.select-task-form {
    &__wrap {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--body-bg);
        box-shadow: var(--box-shadow-active) 0px 5px 15px;
        padding: 30px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
    }

    &__form {
        width: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__item {
        display: flex;
        flex-direction: column;
        margin: 10px 0;

        input,
        textarea {
            width: 300px;
            padding: 5px 10px;
            margin-top: 5px;
            border-radius: 10px;
            border: none;
            outline: none;
        }

        label {
            font-weight: bold;
        }

        input {
            padding: 10px;


        }
    }

    &__date {
        display: flex;

        &-item {
            label {
                margin-right: 15px;
                font-weight: bold;
            }

            input {
                width: 70px;
                border: none;
                border-radius: 10px;
                outline: none;
                padding: 5px;
                cursor: pointer;
            }

            margin: 0 20px;
        }


    }

    &__submit {
        margin-top: 20px;

        button {
            margin: 0 10px;
            cursor: pointer;
        }
    }
}