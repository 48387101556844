@import 'scss/variables';

.soft-rules {
    &-tab {
        background-color: var(--calendar-header-active);
        // width: 100%;
        // min-width: 70vw;
        padding: 20px 40px;
        border-radius: 10px;
    }

    &-table {
        margin-top: 20px;

        td,
        th {
            border: 1px solid var(--border-color);
            padding: 10px;

            &:last-child {
                text-align: center;
                min-width: 50px;
            }
        }

        .weekend-percent {
            color: var(--active-text-color);
            font-size: 16px;
            text-align: center;
            margin: 5px;
            width: 50px;
            background-color: transparent;
            outline: none;
            border: none;
            border-bottom: 1px dashed var(--active-text-color);

        }
    }

    &-submit {

        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        button {
            margin: 0 10px;
        }

        .soft-rules-param__wrap {
            span {
                margin: 5px;
            }

        }
    }

}