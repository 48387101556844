@import 'scss/variables';

.survey-question-form {
    padding: 10px;
    max-width: 100%;
    overflow: scroll;

    &__content {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: var(--modal-background);
        top: 0;
        left: 0;
        display: flex;
        z-index: 9999;
        justify-content: center;
        align-items: center;
    }

    &__wrap {
        transition: all 0.3s;
        background-color: var(--body-bg);
        padding: 50px;

        box-shadow: var(--box-shadow-active) 0px 5px 15px;
        max-width: 550px;
        min-width: 550px;
        max-height: 507px;
        display: flex;
        // justify-content: center;
        // align-items: center;
        flex-direction: column;

    }

    &__header {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
            margin: 0;
            padding: 0;
            color: var(--active-text-color);
        }

        svg {
            right: 0;
            cursor: pointer;
            font-weight: 900;
            font-size: 18px;
            color: var(--active-text-color);

            &:hover {
                color: var(--text-color);
            }
        }
    }

    &__group {
        margin: 20px 0;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        overflow: scroll;
        transition: all 0.3s;



        &-input {
            display: flex;
            transition: all 0.3s;



        }

        .input__option {
            min-width: 50px !important;
        }

        label {
            margin-bottom: 20px;
            font-weight: 700;
            color: var(--active-text-color);
        }

        textarea {
            height: 80px;
            background-color: var(----calendar-plan-number);
            color: var(--active-text-color);
            border: 1px solid var(--border-color);
        }

        input,
        select {
            padding: 10px;
            border-radius: 5px;
            border: 1px solid var(--border-color);
            min-width: 400px;
            color: var(--active-text-color);
            background-color: var(----calendar-plan-number);
        }

        &-property {
            display: flex;
            width: 100%;
            align-items: baseline;
            // justify-content: ;

            input {
                min-width: 30px;
                width: 30px;
            }

            &__item {
                height: 100%;
                margin-right: 20px;

                label {
                    margin-right: 10px;
                }
            }
        }
    }

    &__submit {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        button {
            border-radius: 5px !important;
            margin-right: 10px;
        }
    }

    &__option-input {
        min-width: 50px !important;
    }

}