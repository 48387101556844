@import 'scss/variables';

.calendar-plan-content {
    &__wrap {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 50px 0;
        
        table {
            width: 100%;
            border-spacing: 0;
            border-collapse: collapse;
            color: var(--active-text-color);


        }
    }
}