.multiple-tab {
    display: flex;

    border-bottom: 1px solid var(--border-color);

    &__wrap {
        margin: 0 20px;
        width: 100%;
    }

    &__item {
        padding: 10px;
        width: 100px;
        text-align: center;
        background-color: var(--calendar-header-item);
        cursor: pointer;
        font-weight: bold;

        &.active {
            background-color: var(--calendar-header-active);
            border: 1px solid var(--border-color);
            border-bottom: none;
            border-radius: 8px 8px 0px 0px;
            position: relative;
            color: var(--active-text-color);

          

        }
    }
}