@import 'scss/variables';

.plan-list-row {
    input {
        border: none;
        outline: none;
        width: 100%;
        background: transparent;
        font-size: 14px;

    }

    .time {
        &__wrap {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        text-align: center;
    }
}

.plan-task-table-name {
    min-width: 300px;

    &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
            width: 30px;
            padding: 5px;
            text-align: center;
            color: var(--text-color);
            background-color: var(--calendar-plan-number);
            border: none;
            outline: none;
        }
    }

    svg {
        margin-right: 10px;
        transition: all 0.3s;
        cursor: pointer;

        &:hover {
            color: var(--text-color) !important;
        }
    }
}