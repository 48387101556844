@import 'scss/variables';

.footer__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    border-top: 1px solid var(--text-color);
    padding: 0 20px;
    z-index: 99;
    position: relative;
    background-color: var(--body-bg);

    .coppy-right {
        font-size: 14px;
        color: var(--active-text-color);
        font-weight: 500;
    }

    .change-theme__wrap {
        display: flex;
        align-items: center;
        font-weight: 500;

        .change-theme {
            margin-left: 10px;
            border-radius: 5px;
            border: 1px solid var(--active-text-color);
            background-color: var(--secondary-color);
        }
    }
}