@import 'scss/variables';

.create-staff-task-form {

    &__title {
        color: var(--active-text-color);
        margin: 0;
    }

    &__header {
        position: relative;
        color: var(--active-text-color);



        svg {
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;
            color: var(--active-text-color);
            font-size: 18px;

            &:hover {

                color: var(---text-color);
            }
        }
    }

    &__wrap {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: var(--modal-background);
        top: 0;
        left: 0;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;

        input {
            color: var(--active-text-color);
        }

    }

    &__content {
        background-color: var(--body-bg);
        padding: 30px;
        width: 550px;
    }

    &__group {
        margin: 20px 0;
        display: flex;
        flex-direction: column;

        &-time {
            display: flex;
            margin-right: 50px;
            align-items: center;

            &__label {
                margin-right: 20px;
                color: var(--active-text-color);
                font-weight: 700;
            }

            &__wrap {
                display: flex;
            }

        }

        &-select {
            &__wrap{
                display: flex;
            }
            select {
                margin-left: 10px;
                color: var(--active-text-color);
                padding: 10px;
                border-radius: 5px;
                border: 1px solid var(--border-color);
                background-color: var(--calendar-header-item);
                color: var(--active-text-color);
                min-width: 100px;
                margin-right: 20px;
            }
        }
    }

    &__role-tags {
        margin: 10px 0;

        .role-tag {
            margin: 0 5px;
            background-color: var(--calendar-header-item);
            border-radius: 5px;
            border: 1px solid var(--border-color);
            padding: 5px;
            font-size: 12px;

            &:hover {
                color: var(--active-text-color);
            }
        }
    }

    &__label {
        font-size: 16px;
        margin-bottom: 10px;
        color: var(--active-text-color);
        font-weight: 700;
    }

    &__input {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid var(--border-color);
        background-color: var(--calendar-header-item);
        color: var(--active-text-color);

        &-time {
            // width: 100%;
            padding: 7px;
            border-radius: 5px;
            border: 1px solid var(--border-color);
            background-color: var(--calendar-header-item);

        }
    }

    &__textarea {
        border: 1px solid var(--border-color);
        border-radius: 5px;
        background-color: var(--calendar-header-item);
        padding: 10px;
        height: 140px;
        overflow: scroll;
        color: var(--active-text-color)
    }

    &__submit {
        display: flex;
        justify-content: flex-end;

        button {
            margin: 0 10px;
            border-radius: 5px !important;
        }
    }
}