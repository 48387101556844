@import "scss/variables";

.calendar-work-enviroment {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__wrap {
    width: 100%;
    font-size: 30px;
    background-color: var(--calendar-header-active);
    min-width: 70vw;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    color: var(--active-text-color);
    .title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 40px;
      text-align: center;
    }
    .policies {
      font-size: 18px;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .number-input {
        width: 100px;
      }
      input:invalid {
        border: 1px red solid;
      }
      .policy-input {
        width: 100%;
        padding: 10px 5px;
        background: transparent;
        border: 1px solid var(--btn-color);
        color: var(--active-text-color);
        text-align: center;
        border-radius: 8px;
        font-size: 18px;
      }
    }
  }

  .submit-btn {
    display: flex;
    justify-content: flex-end;
  }

  &__selection {
    display: flex;
    margin-top: 10px;

    &-item {
      margin: 0 5px;

      label,
      input {
        cursor: pointer;
      }
    }
  }

  &__label {
    font-weight: 700;
  }

  &__group {
    margin-bottom: 20px;

    &-sub-question {
      margin: 10px 20px;
    }

    &-title {
      font-size: 24px;
    }

    &-question {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      padding: 0 20px;

      &-sub-topic {
        padding: 0 20px;
        font-size: 18px;
      }
    }
  }
}
