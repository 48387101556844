@import 'scss/variables';

.plan-list-content {

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        h1 {
            color: var(--active-text-color);
            font-size: 25px;
        }
    }

    &__feature {
        button {
            border-radius: 5px !important;
            margin-left: 10px;
        }
    }
}