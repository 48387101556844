@import 'scss/variables';

.calendar-staff-table {
    background-color: var(--calendar-header-active);
    border-radius: 10px;
    transition: all 0.3s;

    td,
    th {
        padding: 15px 20px;
        text-align: left;
        border: 1px solid var(--border-color);
        border-radius: 10px
    }

    thead {
        th {
            border-top: none;
            color: var(--text-color);
        }

        th:first-child {
            border-left: none;
            text-align: center;
        }

        th:last-child {
            border-right: none;
        }
    }

    tr {
        td:first-child {
            border-left: none;
        }

        td:last-child {
            border-right: none;
        }
    }

    tr:last-child {
        td {
            border-bottom: none;
        }
    }

    .time__wrap {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .time {
        margin: 0 20px;
    }

    &__action {
        svg {
            margin: 0 10px;
            cursor: pointer;

            &:hover {
                color: var(--text-color);
            }
        }
    }

    tr:nth-child(even) {
        background-color: transparent ;
    }
}