@import "scss/variables";

.calendar-plan-wish-list {
  overflow: hidden;
  &__wrap {
    max-height: 500px;
    overflow: auto;
    border-radius: 10px;
    border: solid 1px;
  }

  &__table {
    th {
      padding: 20px 0;
      font-size: 18px;
    }

    .day-off {
      color: gray;
    }

    td {
      .col-item {
        display: flex;
        flex-direction: column;
        padding: 5px 5px 10px 5px;
        gap: 20px;

        &__date {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
}
