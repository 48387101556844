@import 'scss/variables';

.mini-switch {
    &__wrap {
        display: flex;
        border: 1px solid var(--border-color);
        justify-content: space-between;
        // width: 220px;
        border-radius: 30px;
        align-items: center;
        position: relative;
        background-color: var(--green);

        &.on{
        background-color: var(--green);
        }
       &.off{
        background-color: red;
        }

        .active {
            background-color: #fff;
            position: absolute;
            top: 0;
            transition: all 0.3s;
            width: 50%;
            height: 100%;
            border-radius: 30px;
            z-index: 99;


        }

        .active-side {
            color: white;
            font-weight: 500;

            &:hover {
                color: white
            }

        }

        .active.left {
            transform: translateX(0)
        }

        .active.right {
            transform: translateX(100%)
        }
    }

    &__item {
        // padding: 10px 30px;
        z-index: 1;
        cursor: pointer;
        transition: all 0.3;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 50%;
        // min-width: 50px;

        &:hover {
            color: var(--active-text-color);
        }
    }


}