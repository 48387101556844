@import 'scss/variables';

.survey-content-managerment {
    &__wrap {
        position: fixed;
        top: 0;
        left: 0;
        background-color: var(--modal-background);
        width: 100vw;
        height: 100vh;
        z-index: 100;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .close {
            display: flex;
            justify-content: flex-end;
        }
    }

    textarea {
        width: 100%;
        margin: 20px 0;
        background-color: var(--hover-selector);
        border: none;
        outline: none;
        color: var(--active-text-color);
    }

    position: relative;
    background-color: var(--body-bg);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-width: 200px;
    max-width: 70%;
    max-height: 80%;
    overflow: scroll;
    padding: 20px 40px;
    transition: all 0.3s;
}