.calendar-plan {
    &__wrap {
        display: flex;
        justify-content: flex-start;
        margin-right:30px ;
    }

    &__content {
        transition: all 0.3s;
        width:  90%;
        min-height: 100vh;
        padding-left: 30px;
  
    }
}