@import 'scss/variables';

.calendar-filter {
    font-size: 24px;
    display: flex;
    justify-content: center;
    padding: 0 30px;
    padding-top: 50px;
    flex-direction: column;
    position: relative;

    .filter-close {
        position: absolute;
        right: 10px;
        top: 10px;

        &:hover {
            color: var(--active-text-color);
        }
    }

    &__title {
        display: flex;
        align-items: center;
        color: var(--active-text-color);

        svg {
            margin-right: 10px;
        }
    }

    &__group {
        margin: 10px 0;
        max-height: 50vh;
        overflow: scroll;
    }

    &__item {
        margin: 10px 5px;
        font-size: 15px;
        width: 100%;
        font-weight: 700;
        display: flex;
        align-items: center;

        label {
            width: 100%;
            margin: 0 10px;
            display: flex;
            align-items: center;
            transition: all 0.3s;
            .drop-down {
                transform: scale(1.2);
                margin-left: 2px;

            }

            cursor: pointer;

            &:hover {

                color: var(--active-text-color);
            }

        }

        input[type=radio] {
            transform: scale(1.5);
        }

        &-unit {
            margin-left: 30px;
            font-size: 14px;
            max-height: 0px;
            overflow: scroll;
            margin-top: -10px;
            transition: all 0.3s;

            input[type=radio] {
                transform: scale(1.2);
            }

            &.active {
                max-height: 100px;
            }
        }
    }

    &__wrap {
        min-height: 100%;
        width: 80px;
        margin-right: 20px;
        background-color: var(--filter-color);
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
            background-color: var(--hover-selector);

        }

        &.active {
            width: 300px;

            &:hover {
                background-color: var(--filter-color);

            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .calendar-filter{
        svg{
            font-size: 20px  !important ;
        }
    }
}