@import "scss/variables";

.date-picker-custom {
    &-visible::placeholder {
        color: var(--active-text-color);
        text-align: center;
    }

    .react-datepicker__input-container {
        input {
            padding: 5px;
            font-size: 16px;
            width: 100px;
            border-radius: 8px;
            text-align: center;
            border: 1px solid;
            font-weight: 700;
            color: var(--text-color);
            background-color: var(--bg-color);
            cursor: pointer;
        }
    }

    select {
        border: none;
        border-radius: 5px;
        background: transparent;
        margin: 5px 5px;
        outline: none;
        margin-top: 0;
        color: #000;
        font-weight: bold;
        font-size: 0.944rem;
    }

    .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
        display: none;
    }
    .react-datepicker-popper{
        z-index: 9999999999;
    }
}
