@import 'scss/variables';

.change-password-form {
    background-color: var(--header-bg);
    padding: 20px;
    border-radius: 6px;
    min-width: 300px;

    h5 {
        text-align: center;
    }
    position: relative;
&__close{
    position: absolute;
    top: 20px;
    right: 20px;
}

    &__wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100%;
    }

    &__item {
        padding: 10px;
        display: flex;
        flex-direction: column;

        label {
            margin-bottom: 10px;
        }

        input {
            border: none;
            outline: none;
            padding: 10px;
            border-radius: 5px;
            background-color: var(--hover-selector);
        }
    }

}