@import 'scss/variables';

.upload-file-form {
    &__wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        padding: 0 50px;
    }

    &__content {
        min-height: 10vh;
        max-width: 90vw;
        min-width: 50vw;
        overflow: scroll;
        background-color: var(--body-bg);
        border-radius: 20px;
        padding: 50px 10px;
        position: relative;
        
        .date-group {
            display: flex;

            .upload-file-form-group {
                margin-left: 30px;

            }
        }
    }

    &-group {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        align-items: center;

        label {
            margin: 0 5px;
            font-weight: 700;
            color: var(--active-text-color)
        }

        input,
        select {
            margin: 0 5px;
            padding: 10px;
            border-radius: 10px;
            background-color: var(--calendar-header-active);
            border: none;
            outline: none;
            color: var(--active-text-color);

            &[type=text] {
                width: 250px;
            }
        }

        &.link {
            justify-content: flex-start;
            margin-top: 20px;
            width: 100%;
            &__wrap {
                display: flex;
                align-items: flex-end;
                justify-content: center;
            }

            input {
                width: 100%;
            }
        }
    }

    &__close {
        position: absolute;
        right: 20px;
        top: 20px;

        button {
            color: var(--active-text-color);
            border: none;
            background-color: transparent !important;
        }
    }

    &__submit {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
    }

    &__btn {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    display: flex;
    justify-content: space-between;
    width: 100%;
}